import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { Dropdown, Loader } from "components";
import { counterOptions, defaultValue } from "./constants";
import { assestType, ASSETS_CONFIG, gov_guaranteed_loan, LOAN_ASSETS, ORDERBOOK_SERIES } from "../../constants";
import { useCurrency, useDebounce, useNetwork } from "hooks";
import { APIS } from "constant";
import { ActiveCurrencyState, SocketTriggeredOnAssetIdState, userPersonalDetails } from "states";
import { formatNumberWithCommas, useFormatNumber } from "utils";

import styles from "./Balance.module.sass";
// @ts-ignore
import { Json } from "@types/common";
import { AssetPortfolioState } from "../Orders/store/state";
import UseChatroomApi from "@views/Chat/store/chatroomApi";
import UseOrdersApi from "../Orders/store/hook";
import { ChatBidderListModal } from "@views/Chat";
import { LoanTable } from "./loan-table";
import { useWebSocket } from "@hooks/web-socket";

// import { Json } from "@types/common";

interface ICounter {
  price: number;
  quantity: number;
}

export const Balance = () => {
  //globle states
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const personalData = useRecoilValue(userPersonalDetails);
  const setSocketTriggeredOnAssetIdState = useSetRecoilState(SocketTriggeredOnAssetIdState);

  //local state
  const [counter, setCounter] = useState<number | string | ICounter>(
    counterOptions[0]
  );
  const [orderBook, setOrderBook] = useState<any>({ bid: [], ask: [] });
  const [bidsData, setBidsData] = useState([]);
  const [asksData, setAsksData] = useState([]);
  const [fetchingApi, setFetchApi] = useState(false);
  const [subAsset, setSubAsset] = useState<any>([]);
  const [subAssetDropdownValue, setSubAssetDropdownValue] = useState("");
  const [subAssetId, setSubAssetId] = useState("");
  const [subAssetCurrLoanBal, setSubAssetCurrLoanBal] = useState("");
  const [subAssetGovGuarLoanBal, setSubAssetGovGuarLoanBal] = useState("");
  const [isSubAssetDropdownValueSet, setIsSubAssetDropdownValueSet] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  //hooks
  const { get: getOrderBook} = useNetwork({ updateState: false });  
  const { get: getSubAsset } = useNetwork({ updateState: false });
  const { numberDecimal } = useFormatNumber();
  const {
    formatCurrencyNumber,
    formatCurrencyWithBillion,
    amountToBondPoints,
  } = useCurrency();
  const { getChatInitiated, chatInitiatedLoading } = UseChatroomApi();
  const [chatClickedData, setChatClickedData] = useState<Json>({});
  const assetPortfolio = useRecoilValue(AssetPortfolioState);
  const { getUsersAssetPortfolio } = UseOrdersApi();
  const [chatBidderModal, setChatBidderModal] = useState<Json | null>(null);
  const debounceValue = useDebounce(searchValue);
  const [offsetValue, setOffsetValue] = useState(0);
  const [lastOffset, setLastOffset] = useState(0);
  const { socket } = useWebSocket();

  const { type, id: activeCurrencyId } = activeCurrency;

  const isOrderBook = activeCurrency?.sbaType === ORDERBOOK_SERIES;
  const isGovGuarunteed = activeCurrency?.loanType === gov_guaranteed_loan;

  useEffect(() => {
    const pendingList = new Array(counter).fill({ price: 0, quantity: 0 });

    let { bid, ask } = orderBook?.data ?? { bid: [], ask: [] };

    if (ask) {
      ask = [...ask, ...pendingList];
      ask = ask.slice(0, counter);
    }

    if (bid) {
      bid = [...bid, ...pendingList];
      bid = bid.slice(0, counter);
    }
    setBidsData(bid);

    setAsksData(ask);
  }, [counter, orderBook]);

  useLayoutEffect(() => {
    setOrderBook({ bid: [], ask: [] });
  }, [activeCurrencyId]);

  useEffect(() => {
    if (subAssetId || activeCurrencyId) {
      getUsersAssetPortfolio(subAssetId || activeCurrencyId);
    }
  }, [activeCurrencyId, getUsersAssetPortfolio, subAssetId])

  useEffect(()=>{
    setSearchValue("");
    setOffsetValue(0)
  },[activeCurrencyId])

  useEffect(()=>{
    setOffsetValue(0)
  },[debounceValue])

  const abortControllerRef = useRef<any>(null);
  const fetchSubAsset = useCallback((offset: number) => {
    const { signal } = abortControllerRef.current;
    setLoading(true)
    getSubAsset(
      `${APIS.SubAsset}?parentId=${activeCurrencyId}&limit=20&offset=${offset}&search=${debounceValue ?? ""}`,
      { signal }
    ).then((resp) => {
      if (resp?.data) {
        setSubAsset((prev: any) => ([...prev, ...resp?.data?.loans]));
        setLastOffset(Math.floor(resp?.data?.count / 20))
        setLoading(false)
      }
    });

  },[activeCurrencyId, getSubAsset, abortControllerRef.current, debounceValue, offsetValue]);

  useEffect(() => {
    setSubAsset([]);
    setIsSubAssetDropdownValueSet(false);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    isOrderBook && fetchSubAsset(offsetValue)
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };

  },[activeCurrencyId, isOrderBook, debounceValue, offsetValue]);



  const subAssetSymbols = useMemo(() => {
    return subAsset.map((item: any) => item.symbol);
  }, [subAsset])

  useEffect(() => {
    if (subAssetSymbols.length > 0 && !isSubAssetDropdownValueSet) {
      setSubAssetDropdownValue(subAssetSymbols[0]);
      setIsSubAssetDropdownValueSet(true);
    }
  }, [subAssetSymbols, isSubAssetDropdownValueSet]);

  useEffect(() => {
    if (subAssetDropdownValue) {
      const subAssetID = subAsset.find((item: any) => {
        return item.symbol === subAssetDropdownValue
      });
      setSubAssetId(subAssetID?.id);
      setSubAssetCurrLoanBal(subAssetID?.current_loan_balance)
      setSubAssetGovGuarLoanBal(subAssetID?.government_guaranteed_loan_balance)
    }
  }, [subAssetDropdownValue, subAsset]);

  const fetchApi = useCallback((assetId: string) => {
    getOrderBook(
      `${APIS.OrderBookBuy}?assetId=${assetId}&status=PENDING&limit=${counter}`
    ).then((orderBookData) => {
      setOrderBook(orderBookData);
    }).catch((err) => {
      console.log("ERRRRRRR", err);
    });
  }, [getOrderBook, activeCurrencyId, counter]);

  useEffect(() => {
    if (
      activeCurrencyId &&
      (type === assestType?.privates || (type === assestType?.realEstate) ||
        type === assestType?.music ||
        LOAN_ASSETS[type])
    ) {
      isOrderBook ?
        subAssetId && fetchApi(subAssetId)
        : fetchApi(activeCurrencyId);
    }
  }, [activeCurrencyId, fetchingApi, type, subAssetId, isOrderBook, counter]);

  useEffect(() => {
    if (!(isOrderBook && subAssetId)) return;
    socket?.on(`${subAssetId}`, (data) => {
      if (data?.type === "ORDERBOOK") {
        handleSocketBid(data);
      }
    });
    return () => {
      socket?.off(`${subAssetId}`);
    }
  }, [socket, subAssetId, isOrderBook, orderBook]);

  useEffect(() => {
    socket?.on(`${activeCurrencyId}`, (data) => {
      if (data?.type === "CHART" || data?.type === "BIDSHEET") {
        setSocketTriggeredOnAssetIdState({ ...data, timeStamp: new Date().getTime() });
      }
      if (data?.type === "ORDERBOOK") {
        handleSocketBid(data);
      }
    });
    return () => {
      socket?.off(`${activeCurrencyId}`);
    }
  }, [socket, activeCurrencyId, orderBook]);

  const handleSocketBid = (data: Json) => {
    let { ask, bid } = orderBook?.data ?? { bid: [], ask: [] };
    let bidArray = data?.metadata?.type === "SELL" ? ask : bid;
    const quantity = parseFloat(data?.metadata?.quantity || 0);
    const askIndex = bidArray?.findIndex((item: any) => parseFloat(item.price) === parseFloat(data?.metadata?.price));
    if (data?.metadata?.action === "add") {
      if (askIndex === -1) {
        const addIndex = [...bidArray, { price: "0.0000" }]?.findIndex((item: any) => {
          if (data?.metadata?.type === "SELL")
            return parseFloat(item.price) > 0 ? parseFloat(data?.metadata?.price) < parseFloat(item.price) : true
          else return parseFloat(data?.metadata?.price) > parseFloat(item.price)
        });
        bidArray.splice(addIndex || 0, 0, {
          "quantity": quantity || 0,
          "status": "PENDING",
          "type": data?.metadata?.type,
          "price": parseFloat(data?.metadata?.price || 0) ,
          "userId": data?.metadata?.userId,
          "customerId": data?.metadata?.customerId,
        })
      } else {
        bidArray[askIndex] = {
          ...bidArray[askIndex],
          "quantity": bidArray[askIndex]?.quantity + quantity,
          grouped: true,
          "userId": "",
          "customerId": "",
        }
      }
    } else {
      const qty = bidArray[askIndex]?.quantity - quantity;
      if (qty) {
        bidArray[askIndex] = {
          ...bidArray[askIndex],
          "quantity": qty,
        }
      } else {
        bidArray.splice(askIndex, 1);
      }
    }

    if (data?.metadata?.type === "SELL") {
      setOrderBook((prev: any) => ({
        ...prev,
        data: {
          bid: [...(prev?.data?.bid || [])],
          ask: [...bidArray]
        }
      }));
    } else {
      setOrderBook((prev: any) => ({
        ...prev,
        data: {
          ask: [...(prev?.data?.ask || [])],
          bid: [...bidArray]
        }
      }));
    }
  }

  const positiveTotal = useMemo(() => {
    let totalQuantity = 0;
    bidsData.forEach((bid: any) => {
      totalQuantity += Number(bid.quantity);
    });
    return totalQuantity;
  }, [bidsData]);

  const negativeTotal = useMemo(() => {
    let totalQuantity = 0;
    asksData.forEach((asks: any) => {
      totalQuantity += Number(asks.quantity);
    });
    return totalQuantity;
  }, [asksData]);

  const renderPositiveList = useMemo(() => {
    return bidsData?.map((bid: any, index: number) => {
      return (
        bidsData && (
          <div
            className={cn(
              styles.item,

              { [styles.positive]: true }
            )}
            key={index}
          >
            <div className={styles.price}>
              {LOAN_ASSETS[type]
                 ? formatNumberWithCommas(Number(amountToBondPoints(
                    isOrderBook ? isGovGuarunteed ? subAssetGovGuarLoanBal : subAssetCurrLoanBal : activeCurrency?.currentLoanBalance,
                  bid?.price
                )),6):
                 ""}

              {activeCurrency.type === assestType.privates || activeCurrency.type === assestType.realEstate ||
              type === assestType.music
                ? formatCurrencyNumber(Number(bid?.price ?? defaultValue), ASSETS_CONFIG[activeCurrency?.type]?.decimal)
                : LOAN_ASSETS[type]
                ? !!bid?.price &&
                  ` ( ${formatCurrencyWithBillion(bid?.price, ASSETS_CONFIG[activeCurrency?.type]?.decimal)})`
                : defaultValue}
              {!personalData?.isBlockChat &&
              assetPortfolio?.data?.isSeller &&
              personalData?.customerId !== bid?.customerId &&
              !!parseFloat(bid?.price) ? (
                <>
                  <button
                    className={styles.bidChatbtn}
                    onClick={() => handleClickChat(bid, index)}
                    disabled={chatInitiatedLoading}
                  >
                    {chatClickedData?.type === "BUY" &&
                    chatClickedData?.idx === index &&
                    chatInitiatedLoading ? (
                      <Loader dimension={17} />
                    ) : (
                      <>
                        <i className="ri-chat-voice-fill" /> Chat
                      </>
                    )}
                  </button>
                </>
              ) : (
                ""
              )}
            </div>

            <div className={styles.quantity}>
              {activeCurrency.type === assestType.privates || activeCurrency.type === assestType.realEstate ||
              type === assestType.music ||
              LOAN_ASSETS[type]
                ? formatNumberWithCommas(Number(bid?.quantity), 2, false) ?? defaultValue
                : formatNumberWithCommas(defaultValue)}
            </div>
            <div className={styles.line}></div>
          </div>
        )
      );
    });
  }, [bidsData, type, amountToBondPoints, isOrderBook, isGovGuarunteed, subAssetGovGuarLoanBal, subAssetCurrLoanBal, activeCurrency?.currentLoanBalance, activeCurrency.type, formatCurrencyNumber, formatCurrencyWithBillion, personalData?.isBlockChat, personalData?.customerId, assetPortfolio?.data?.isSeller, chatInitiatedLoading, chatClickedData?.type, chatClickedData?.idx, numberDecimal]);

  const checkAsset = useMemo(() => {
    return (
      activeCurrency.type === assestType.privates ||
      activeCurrency.type === assestType.realEstate ||
      activeCurrency.type === assestType.music ||
      LOAN_ASSETS[type]
    );
  }, [activeCurrency.type, type]);

  const renderNegativeList = useMemo(() => {
    return asksData.map((ask: any, index: number) => {
      return (
        asksData && (
          <div
            className={cn(
              styles.item,

              { [styles.negative]: true }
            )}
            key={index}
          >
            <div className={styles.price}>
              {LOAN_ASSETS[type] && ask?.price >= 0
                ? formatNumberWithCommas(Number(
                    amountToBondPoints(
                      isOrderBook
                        ? isGovGuarunteed
                          ? subAssetGovGuarLoanBal
                          : subAssetCurrLoanBal
                        : activeCurrency?.currentLoanBalance,
                      ask?.price
                    )
                  ),6)
                : ""}

              {checkAsset
                ? LOAN_ASSETS[type]
                  ? !!ask?.price &&
                    ` ( ${formatCurrencyWithBillion(ask?.price, 2)})`
                  : formatCurrencyNumber(Number(ask?.price ?? defaultValue), 2)
                : defaultValue}
              {!personalData?.isBlockChat &&
              personalData?.customerId !== ask?.customerId &&
              !!parseFloat(ask?.price) ? (
                <>
                  <button
                    className={styles.askChatbtn}
                    onClick={() => handleClickChat(ask, index)}
                    disabled={chatInitiatedLoading}
                  >
                    {chatClickedData?.type === "SELL" &&
                    chatClickedData?.idx === index &&
                    chatInitiatedLoading ? (
                      <Loader dimension={17} />
                    ) : (
                      <>
                        <i className="ri-chat-voice-fill" /> Chat
                      </>
                    )}
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
            <div className={styles.quantity}>
              {checkAsset
                ? formatNumberWithCommas(Number(ask?.quantity ?? defaultValue), 2, false)
                : formatNumberWithCommas(defaultValue)}
            </div>
            <div className={styles.line}></div>
          </div>
        )
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeCurrency?.currentLoanBalance,
    asksData,
    checkAsset,
    type,
    chatInitiatedLoading,
    chatClickedData,
  ]);

  const handleClickChat = useCallback((data: Json, idx: number) => {
    if (data?.grouped) {
      setChatBidderModal({
        ...data,
        assetId: activeCurrency?.id || "",
        currentLoanBalance: isOrderBook ? isGovGuarunteed ? subAssetGovGuarLoanBal : subAssetCurrLoanBal : activeCurrency?.currentLoanBalance,
        assetType: activeCurrency?.type || "",
      });
    } else {
      setChatClickedData({ ...data, idx: idx });
      getChatInitiated({
        assetId: activeCurrency?.id || "",
        receiverId: data?.customerId || "",
        senderId: personalData?.customerId || "",
      })
    }
  }, [activeCurrency, isOrderBook, isGovGuarunteed, subAssetGovGuarLoanBal, subAssetCurrLoanBal, getChatInitiated])

  return (
    <div className={cn(
      styles.outerTable,
      { [styles.outerTableGrid]: isOrderBook }
    )}>
    {isOrderBook &&
    <LoanTable value={subAssetDropdownValue as string}
      setValue={setSubAssetDropdownValue}
      options={subAsset}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      offsetValue={offsetValue}
      setOffsetValue={setOffsetValue}
      lastOffset={lastOffset}
      loading = {loading}
      />}
    <div className={styles.balance}>
      {!!chatBidderModal && (
        <ChatBidderListModal
          open={!!chatBidderModal}
          details={chatBidderModal}
          onClose={() => setChatBidderModal(null)}
        />
      )}

      <div className={styles.head}>
        <div className={styles.orderBook}>Order Book</div>
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          classDropdownArrow={styles.dropdownArrow}
          classDropdownBody={styles.dropdownBody}
          classDropdownOption={styles.dropdownOption}
          value={counter as number | string}
          setValue={setCounter}
          options={counterOptions}
          autoDirection={false}
        />
      </div>

      <div className={styles.container}>
        <div className={styles.contents}>
          <div className={styles.top}>
            <div className={styles.price}>
              {LOAN_ASSETS[type] ? "Bid in Bond Points ($)" : "Bid Price"}
            </div>
            <div className={styles.quantity}>Qty.</div>
          </div>
          <div className={styles.list}>{renderPositiveList}</div>
          <div className={styles.total}>
            <div>Total</div>
            <div className={styles.totalValue}>
              {activeCurrency.type === assestType.privates || activeCurrency.type === assestType.realEstate ||
              type === assestType.music ||
              LOAN_ASSETS[type]
                ? formatNumberWithCommas(positiveTotal)
                : formatNumberWithCommas(defaultValue)}
            </div>
          </div>
        </div>

        <div className={styles.contents}>
          <div className={styles.top}>
            <div className={styles.price}>{LOAN_ASSETS[type] ? "Ask in Bond Points ($)" : "Asking Price"}</div>
            <div className={styles.quantity}>Qty.</div>
          </div>
          <div className={`${styles.list} ${styles.ask}`}>
            {renderNegativeList}
          </div>
          <div className={styles.total}>
            <div>Total</div>
            <div className={styles.totalValue}>
              {activeCurrency.type === assestType.privates || activeCurrency.type === assestType.realEstate ||
              type === assestType.music ||
              LOAN_ASSETS[type]
                ? formatNumberWithCommas(negativeTotal)
                : formatNumberWithCommas(defaultValue)}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
