import { IAction, IBioMetrixInfo } from "../../type";

import React, { ChangeEvent, FC, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import cn from "classnames";

import { CountdownTimer, Loader, RadialSeparators, ReactModal, Skeleton, } from "components";
import {
  convertToDays,
  formatNumberWithCommas, getNumberWithDynamicDecimal,
  mergeStringWithSymbol,
  numberDecimalValidate,
  numberValidate,
  useFormatNumber,
} from "utils";
import {
  ActiveCurrencyState,
  defaultFormDatastate,
  defaultModifyDatastate,
  goodTillCancelledState,
  InfoState,
  isBalanceState,
  IsOpenAssetLoadingState,
  SelectedOrderState,
  userPersonalDetails,
  userSelectedCurrencyState,
  UsersLoadingState,
  VerfyOrderState,
  VerifyOrderModalType,
  VisibleCancelState,
  VisibleModifyState,
} from "states";
import { useNetwork, useNotification, useThrottle, useWehAuthn } from "hooks";
import { APIS, Messages } from "constant";
import {
  actions,
  assestType,
  ASSETS_CONFIG,
  gov_guaranteed_loan,
  LOAN_ASSETS,
  ORDERBOOK_SERIES,
  PROVIDER,
} from "views/exchange/constants";
import { useCurrency } from "hooks/currency";
import { ReactSwitch } from "@storybook";

import styles from "./Action.module.sass";
import "./Action.scss";
import { UseTransaction } from "views/MyWallet/store/hooks";
import {
  AssetPortfolioState,
  primaryIssuanceState,
} from "@views/exchange/components/Orders/store/state";
import useAuctionHook from "@views/AuctionConsumer/hook";
import { DeviceBiometrics } from "@components/device-biometrics";
import { FortressAccountInfo } from "@views/MyWallet/store/state";
import BuysellConfirmation from "../buysellConfimation/buysellConfirmation";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import { ALLOW_MPC_ENV } from "../../../../../../envs";
import { IssuanceSignDoc } from "@views/exchange/components";
// @ts-ignore
import { Json } from "@types/common";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import UseOrdersApi from "@views/exchange/components/Orders/store/hook";
import { CommaSeparatedInput } from "@storybook/comma-separated-input/comma-seprated-input";
import { addMilliseconds, differenceInMilliseconds } from "date-fns";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { MUTUAL_FUND } from "@views/MyOrders/constants";

const {
  ZERO_PRICE_ERROR,
  ZERO_LIMIT_ERROR,
  ZERO_QTY_ERROR,
  ORDER_ERROR_MESSAGE,
  LIMIT_BUY_MESSAGE,
  LIMIT_SELL_MESSAGE,
  BUYCONFIRMATION,
  SELLCONFIRMATION,
} = Messages;

enum ModalType {
  CONFIRM,
  APPROVED,
  DECLINE,
  TIMEUP,
  DEFAULT,
  MODIFIED,
  CANCELLED,
}

export const Action: FC<IAction> = ({
  type,
  activeTabIndex,
  content,
  StopLimit,
  classButton,
  buttonText,
  isOrderModifying,
  isModifyOrderOpen = false,
}) => {

  //global states
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const setInfoData = useSetRecoilState(InfoState);
  const selectedOrder = useRecoilValue(SelectedOrderState);
  const setVisibleModify = useSetRecoilState(VisibleModifyState);
  const [goodTillCancelled, setGoodTillCancelled] = useRecoilState(
    goodTillCancelledState
  );

  // We need to update orderTab orderPlaceState to trigger order details api
  const [primaryIssuance, setPrimaryIssuance] =
    useRecoilState(primaryIssuanceState);
  const UserLoading = useRecoilValue(UsersLoadingState);
  const { getUsersAssetPortfolio } = UseOrdersApi();
  const personalData = useRecoilValue(userPersonalDetails);

  const { calculateWeightedYieldPer } = useAuctionHook();
  const { getWebAuthnSupported } = useWehAuthn();

  const currencyDetails = useRecoilValue(userSelectedCurrencyState);
  const isOpenAssetLoading = useRecoilValue(IsOpenAssetLoadingState);
  const formDataInitial = useRecoilValue(defaultFormDatastate);
  const [formData, setFormData] = useRecoilState(defaultModifyDatastate);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const availableBalance = useRecoilValue<any>(FortressAccountInfo);
  const setIsBalance = useSetRecoilState(isBalanceState);
  const [isbiometricLoading, setIsbiometricLoading] = useState(false);
  const [initiateBiometric, setInitiateBiometrics] = useState(false);
  const { trackEvent } = useFullStoryTrackEvent();
  const isTimerCompleted = useRef<boolean>(false);
  const inputChangeCheck = useRef<Json>({});

  const bondPoint =
    formData.price &&
    typeof formData.price === "string" &&
    parseFloat(formData.price.split(".")[0]) > 1
      ? "bond points"
      : "bond point";

  useEffect(() => {
    if (formDataInitial.price) {
      if (differenceInMilliseconds(inputChangeCheck.current?.delay, new Date()) > 0) return
      const priceValue = getNumberWithDynamicDecimal(Number(formDataInitial?.price)?.toFixed(ASSETS_CONFIG[activeCurrency?.type]?.decimal))
      handleChange(priceValue, "price")
    }
  }, [formDataInitial]);

  const {
    id: activeCurrencyId,
    marketPrice,
    symbol,
    amount,
    currentLoanBalance,
    issuerDetails,
    musicId,
    fee,
    lastTradedPrice,
  }: any = activeCurrency ?? {};

  const { issuerName } = issuerDetails || {};
  //local state
  const [dateTime, setDateTime] = useState(0);
  const [isBtnDisable, setBtnDisable] = useState(false);
  const setModalType = useSetRecoilState<ModalType>(VerifyOrderModalType);
  const setOpenVerfyOrder = useSetRecoilState(VerfyOrderState);
  const setVisibleCancelOrder = useSetRecoilState(VisibleCancelState);

  // hooks
  const { post: postOrder, loading } = useNetwork();
  const { put: modifyOrder, loading: modifyLoading } = useNetwork();
  const { get: getInfo } = useNetwork();
  const { post: postIssuanceUrl, loading: issuanceLoading } = useNetwork();
  const { errorNotification, successNotification } = useNotification();

  const {
    currencyConvertInNumber,
    amountToBondPoints,
    bondPointToAmount,
    formatCurrencyWithBillion,
  } = useCurrency();
  const { numberDecimal } = useFormatNumber();
  const { getFortressAccountInfo } = UseTransaction();
  const assetPortfolio = useRecoilValue(AssetPortfolioState);

  const { get: getOrders } = useNetwork();
  const isOrderBook = activeCurrency?.sbaType === ORDERBOOK_SERIES;
  const isGovGuarunteed = activeCurrency?.loanType === gov_guaranteed_loan;

  const insufficientFundsArr = [
    "Failed to create order.: You do not have enough balance in your wallet",
    "Failed to create order......: You do not have enough balance in your wallet",
    "Failed to create order: You do not have enough balance in your wallet",
    "Failed to update order: You do not have enough balance in your wallet",
  ];

  const assetPrice = LOAN_ASSETS[activeCurrency?.type]
    ? isOrderBook
      ? amountToBondPoints(
          isGovGuarunteed
            ? selectedOrder?.government_guaranteed_loan_balance
            : selectedOrder?.current_loan_balance,
          selectedOrder?.price
        )
      : amountToBondPoints(currentLoanBalance, amount)
    : marketPrice || amount || lastTradedPrice;

  useEffect(() => {
    if (symbol) {

      setFormData((prev: any) => {
        const price = isOrderModifying
          ? (
            Number(
              LOAN_ASSETS[activeCurrency?.type]
                ? amountToBondPoints(
                  isOrderBook
                    ? isGovGuarunteed
                      ? selectedOrder?.government_guaranteed_loan_balance
                      : selectedOrder?.current_loan_balance
                    : currentLoanBalance,
                  selectedOrder?.price
                )
                : selectedOrder?.price
            ) * currencyDetails?.rate
          ).toFixed(ASSETS_CONFIG[activeCurrency?.type]?.decimal)
          : getNumberWithDynamicDecimal((assetPrice * currencyDetails?.rate).toFixed(ASSETS_CONFIG[activeCurrency?.type]?.decimal));
        const qty = isOrderModifying ? Math.round(selectedOrder?.quantity) : "1";
        const total = getNumberWithDynamicDecimal((Number(price) * Number(qty)).toFixed(ASSETS_CONFIG[activeCurrency?.type]?.decimal))
        return {
          ...prev,
          symbol: isOrderBook ? selectedOrder?.symbol : symbol, price, qty,
          total,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOrderModifying,
    assetPrice,
    selectedOrder?.price,
    symbol,
    currencyDetails?.rate,
  ]);

  useEffect(() => {
    const { price, limit, qty, total } = formData;
    const totalPrice = Number(formData?.price) * Number(formData?.qty);
    if (activeTabIndex === 2) {
      if (!parseFloat((price || 0) as string) || !qty || !limit) {
        return setBtnDisable(true);
      }
      setBtnDisable(false);
    } else {
      let disableCondition = !parseFloat((price || 0) as string) || !qty;
      if (
        ( activeCurrency?.category) === assestType.crypto
      ) {
        disableCondition = parseFloat((total || 0) as string) < 1 || !qty;
      }
      if (
        !ALLOW_MPC_ENV &&
        totalPrice < activeCurrency?.minimumTradePrice &&
        activeCurrency?.isIssuanceEnabled
      ) {
        return setBtnDisable(true);
      }
      if (disableCondition) {
        return setBtnDisable(true);
      }
      setBtnDisable(false);
    }
  }, [activeTabIndex, formData, activeCurrency?.category]);

  const handleWallet = useCallback(
    (TotalAmount: number) => {
      if (type === "BUY") {
        setInfoData((prev) => {
          return { ...prev, balance: prev.balance - TotalAmount };
        });
      }
    },
    [setInfoData, type]
  );

  const handleSubmit = useCallback(
    async (
      price: string | number,
      qty: string | number,
      limit: string | undefined,
      fieldData?: Json
    ) => {
      if (Number(price) === 0) {
        return errorNotification(ZERO_PRICE_ERROR);
      }
      if (Number(qty) === 0) {
        return errorNotification(ZERO_QTY_ERROR);
      }
      if (StopLimit) {
        if (Number(limit) === 0) {
          return errorNotification(ZERO_LIMIT_ERROR);
        }
        if (type === actions.BUY && Number(limit) < Number(price)) {
          return errorNotification(LIMIT_BUY_MESSAGE);
        }
        if (type === actions.SELL && Number(limit) > Number(price)) {
          return errorNotification(LIMIT_SELL_MESSAGE);
        }
      }
      setVisibleCancelOrder(false);
      const priceValue = Number(getNumberWithDynamicDecimal(
        (assetPrice * currencyDetails.rate).toFixed(
          ASSETS_CONFIG[activeCurrency?.type]?.decimal
        )));
      setFormData((prev: any) => {
        return {
          ...prev,
          symbol,
          price: priceValue
        };
      });

      const modifyApiUrl = ALLOW_MPC_ENV
        ? APIS.ORDERS_EXCHANGE_MODIFICATION
        : APIS.OrderHistory;
      const orderApiUrl = ALLOW_MPC_ENV ? APIS.ORDERS_EXCHANGE : APIS.Offers;
      const url = isOrderModifying
        ? `${modifyApiUrl}/${selectedOrder?.id}`
        : orderApiUrl;
      let payload: Json = {};

      // this is to be used when SECRET_KEY from keychain will be available
      const SECRET_KEY: string = "";
      const modifiedCurrency = LOAN_ASSETS[activeCurrency?.type]
        ? Number(
          bondPointToAmount(
            isOrderBook
              ? isGovGuarunteed
                ? selectedOrder?.government_guaranteed_loan_balance
                : selectedOrder?.current_loan_balance
              : currentLoanBalance,
            price
          ).toFixed(2) || 0
        )
        : Number(getNumberWithDynamicDecimal(currencyConvertInNumber(price, ASSETS_CONFIG[activeCurrency?.type]?.decimal)));
      if (type === "BUY") {
        payload = isOrderModifying
          ? {
            price: modifiedCurrency,
            quantity: qty,
          }
          : {
            assetId: activeCurrencyId,
            quantity: qty,
            price: modifiedCurrency,
            type,
            goodTillCancelled: goodTillCancelled,
          };
      } else {
        payload = isOrderModifying
          ? {
            price: modifiedCurrency,
            quantity: qty,
          }
          : {
            assetId: activeCurrencyId,
            quantity: qty,
            price: modifiedCurrency,
            type,
            goodTillCancelled: goodTillCancelled,
          };
      }

      if (SECRET_KEY?.length > 0) {
        payload = { ...payload, payer: SECRET_KEY };
      }

      if (!ALLOW_MPC_ENV && activeCurrency?.isIssuanceEnabled) {
        payload = { ...payload, signDocId: primaryIssuance?.signDocId };
      }
      setDateTime(new Date().getTime());

      if (activeCurrency?.type === assestType.crypto) {
        payload.quantity = Number(Number(qty)?.toFixed(8));
        payload.totalPrice = Number(Number(fieldData?.total)?.toFixed(8));
        payload.orderType = "market";
        payload.tradeOn = inputChangeCheck.current?.key === "qty" ? "quantity" : "price";
      }

      if (isOrderModifying) {
        modifyOrder(url, payload).then((res) => {
          if (res) {
            if (insufficientFundsArr.includes(res?.message)) {
              setIsBalance(true);
              return;
            }
          }
          const condition = ALLOW_MPC_ENV
            ? res?.data?.orderRequestId
            : res?.id;
          if (condition) {
            if (ALLOW_MPC_ENV) {
              setOpenVerfyOrder({
                ...res?.data,
                VerificationType: "modifyOrder",
              });
              setModalType(ModalType.CONFIRM);
            }
            setVisibleModify(false);
            trackEvent(`Order Modified`, {
              ...payload,
            });
            getInfo(APIS.Info).then((wallet) => {
              if (wallet?.data?.stellar) {
                setInfoData(wallet?.data?.stellar);
              }
            });
          } else {
            errorNotification(res?.message ?? ORDER_ERROR_MESSAGE);
          }
        }).catch((err) => {
          errorNotification(err?.message ?? ORDER_ERROR_MESSAGE);
        });
      } else {
        postOrder(url, payload).then((res) => {
          if (res) {
            if (insufficientFundsArr.includes(res?.message)) {
              setIsBalance(true);
              return;
            }
          }
          const condition = ALLOW_MPC_ENV
            ? res?.data?.orderRequestId
            : res?.assetId;
          if (condition) {
            if (ALLOW_MPC_ENV) {
              setOpenVerfyOrder(res?.data);
              setModalType(ModalType.CONFIRM);
            }

            const totalAmount = Number(price) * Number(qty);
            handleWallet(totalAmount);
            handleChange(1, "qty");
            getFortressAccountInfo();
            trackEvent(`Order Placed`, {
              ...payload,
            });
            getInfo(APIS.Info).then((wallet) => {
              if (wallet?.data?.stellar) {
                setInfoData(wallet?.data?.stellar);
              }
            });
          } else {
            errorNotification(
              res?.message?.replace(/\./g, " ") ??
              ORDER_ERROR_MESSAGE
            );
          }
        }).catch((err) => {
          errorNotification(err?.message ?? ORDER_ERROR_MESSAGE);
        });
      }
    },
    [
      StopLimit,
      activeCurrency?.isIssuanceEnabled,
      activeCurrency?.type,
      activeCurrencyId,
      assetPrice,
      bondPointToAmount,
      currencyConvertInNumber,
      currencyDetails.rate,
      currentLoanBalance,
      errorNotification,
      getFortressAccountInfo,
      getInfo,
      getOrders,
      goodTillCancelled,
      handleWallet,
      insufficientFundsArr,
      isGovGuarunteed,
      isOrderBook,
      isOrderModifying,
      modifyOrder,
      postOrder,
      primaryIssuance?.signDocId,
      selectedOrder?.current_loan_balance,
      selectedOrder?.government_guaranteed_loan_balance,
      selectedOrder?.id,
      setInfoData,
      setModalType,
      setOpenVerfyOrder,
      setVisibleCancelOrder,
      setVisibleModify,
      symbol,
      trackEvent,
      type,
    ]
  );

  const handleSignDoc = useCallback(() => {
    const { price, qty: quantity } = formData ?? {};
    const total = Number(formData?.price) * Number(formData?.qty);
    const assetId = activeCurrency?.id;
    postIssuanceUrl(APIS.PRIMARY_ISSUANCE, {
      price,
      quantity,
      total,
      assetId,
    }).then((res) => {
      if (res?.docUrl) {
        setVisibleCancel(false);

        setPrimaryIssuance({ ...res, signDocModal: true });
      } else {
        errorNotification(res?.message);
      }
    });
  }, [activeCurrency?.id, formData]);

  const handelOrderPlace = useCallback(() => {
    setIsbiometricLoading(true);
    if (!getWebAuthnSupported()) {
      handleSuccess();
    } else {
      setInitiateBiometrics(true);
    }
  }, []);

  const handleSuccess = useCallback(
    (bioInfo?: IBioMetrixInfo) => {
      const { price, qty, limit } = formData ?? {};
      handleSubmit(Number(price), Number(qty), limit, formData);
      setInitiateBiometrics(false);
    },
    [formData, handleSubmit]
  );

  const handleFailure = useCallback(() => {
    setInitiateBiometrics(false);
  }, []);

  const throttledErrorNotification = useThrottle((message: string) => {
    errorNotification(message);
  }, 2000);

  const checkDecimalPlaces = (value: string, maxPlaces: number) => {
    const decimalIndex = value.indexOf(".");
    if (decimalIndex !== -1) {
      const decimalPart = value.slice(decimalIndex + 1);
      return decimalPart.length <= maxPlaces;
    }
    return true;
  };

  const handleChange = useCallback(
    (value: any, name: string) => {
      const validQty = ASSETS_CONFIG[activeCurrency?.type]?.qtyRegex?.test(value);
      const validPrice = ASSETS_CONFIG[activeCurrency?.type]?.priceRegex?.test(value);

      if (name === "qty" && !validQty) return;

      if (name === "price" && !validPrice) {
        if (!checkDecimalPlaces(value, ASSETS_CONFIG[activeCurrency?.type]?.decimal - 1)) {
          throttledErrorNotification(
            `You can only enter up to ${ASSETS_CONFIG[activeCurrency?.type]?.decimal} decimal places. `
          );
        }
        return;
      }

      setFormData((prev) => {
        let tempFormData = { ...prev }
        if (name === "price") {
          if (inputChangeCheck.current?.key === "total") {
            tempFormData.qty = (Number(prev?.total) / Number(value));
          } else if (inputChangeCheck.current?.key === "qty") {
            tempFormData.total = getNumberWithDynamicDecimal((Number(prev?.qty) * Number(value)).toFixed(ASSETS_CONFIG[activeCurrency?.type]?.decimal));
          } else {
            tempFormData.total = getNumberWithDynamicDecimal((Number(prev?.qty) * Number(value)).toFixed(ASSETS_CONFIG[activeCurrency?.type]?.decimal));
          }
        }

        if (name === "total") {
          handleInputChangeCheck("total")
          tempFormData.qty = (Number(value) / Number(prev?.price));
        }
        if (name === "qty") {
          handleInputChangeCheck("qty")
          tempFormData.total = getNumberWithDynamicDecimal((Number(value) * Number(prev?.price)).toFixed(ASSETS_CONFIG[activeCurrency?.type]?.decimal));
        }
        return { ...prev, ...tempFormData, [name]: value };
      });
    },
    [setFormData, activeCurrency?.type, throttledErrorNotification, formData]
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>, name: string) => {
      const { key } = e;
      if (name === "price") {
        if (!numberDecimalValidate(key)) {
          e.preventDefault();
        }
      }

      if (name === "total") {
        if (!numberDecimalValidate(key)) {
          e.preventDefault();
        }
      }

      if (name === "limit") {
        if (key === "." && formData.limit?.toString().includes("."))
          e.preventDefault();
        else if (!numberDecimalValidate(key)) {
          e.preventDefault();
        }
      }

      const qtyValidate = activeCurrency?.type === assestType.crypto ? numberDecimalValidate(key) : numberValidate(key);
      if (name === "qty" && !qtyValidate) {
        e.preventDefault();
      }
    },
    [formData.limit]
  );

  const handleToggle = (checked: boolean) => {
    setGoodTillCancelled(checked);
  };

  const handleConfirmBuySell = useCallback(
    async (choosePaymentType: string) => {
      const { price, limit, qty } = formData;
      if (
        type === "SELL" &&
        isOrderModifying &&
        Number(qty) >
        Number(assetPortfolio?.data?.quantity) +
        Number(selectedOrder.quantity)
      ) {
        return errorNotification("Not enough quantity available");
      }
      if (
        type === "SELL" &&
        (Number(qty) > Number(assetPortfolio?.data?.quantity) ||
          !Number(assetPortfolio?.data?.quantity || 0)) &&
        !isOrderModifying
      ) {
        return errorNotification(
          "You’re attempting to sell more quantity than you currently have available for trade. Please adjust the quantity to proceed"
        );
      }
      trackEvent(`Exchange Trading`, {
        type: type,
        assetSymbol: symbol,
        assetId: activeCurrencyId,
        price,
        limit,
        qty,
      });
      if (choosePaymentType === "wallet" && availableBalance?.balance < 0) {
        errorNotification("Wallet balance is not sufficient");
      } else {
        if (personalData?.isTradeEnabled === false) {
          return errorNotification("Trading is not allowed");
        }

        if (
          !ALLOW_MPC_ENV &&
          activeCurrency?.isIssuanceEnabled &&
          type === "BUY"
        ) {
          handleSignDoc();
        } else {
          handelOrderPlace();
        }
      }
    },
    [
      formData,
      type,
      isOrderModifying,
      assetPortfolio?.data?.quantity,
      selectedOrder.quantity,
      trackEvent,
      symbol,
      activeCurrencyId,
      availableBalance?.balance,
      errorNotification,
      personalData?.isTradeEnabled,
      activeCurrency?.isIssuanceEnabled,
    ]
  );

  const handleButtonClick = useCallback(() => {
    const { qty } = formData;

    if (
      type === "SELL" &&
      isOrderModifying &&
      (activeCurrency?.type === 'privates' || activeCurrency?.type === "real_estate") &&
      Number(qty) !== selectedOrder.quantity &&
      Number(qty) >
      (assetPortfolio?.data?.lockInTradeInfo?.unlockedQuantity +
        selectedOrder.quantity)
    ) {
      return errorNotification(
        "You’re attempting to sell more units than you currently have available for trade, and some units are still under the lockup period and cannot be sold. Please adjust the quantity to proceed."
      );
    }

    if (
      type === "SELL" &&
      (activeCurrency?.type === 'privates' || activeCurrency?.type === "real_estate") &&
      (Number(qty) > Number(assetPortfolio?.data?.lockInTradeInfo?.unlockedQuantity) &&
        !isOrderModifying
      )
    ) {
      if (assetPortfolio?.data?.lockInTradeInfo?.lockedQuantity === 0) {
        return errorNotification("You’re attempting to sell more units than you currently have available for trade. Please adjust the quantity to proceed.");
      } else if (assetPortfolio?.data?.lockInTradeInfo?.lockedQuantity > 0) {
        return errorNotification("You’re attempting to sell more units than you currently have available for trade, and some units are still under the lockup period and cannot be sold. Please adjust the quantity to proceed.");
      }
    }

    if (
      type === "SELL" &&
      (Number(qty) > Number(assetPortfolio?.data?.quantity) ||
        !Number(assetPortfolio?.data?.quantity || 0)) &&
      !isOrderModifying
    ) {
      return errorNotification("You’re attempting to sell more quantity than you currently have available for trade. Please adjust the quantity to proceed");
    }
    setVisibleCancel(true);
  }, [formData, type, assetPortfolio?.data?.quantity, isOrderModifying, errorNotification, selectedOrder]);

  const handleClose = useCallback(() => {
    setVisibleCancel(false);
    setInitiateBiometrics(false);
  }, []);

  const capitalizeFirstLetter = useCallback((str: string) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLocaleLowerCase();
  }, []);

  const setModalVisible = useCallback(
    (loading: boolean, isModalOpen: boolean) => {
      setIsbiometricLoading(loading);
      setInitiateBiometrics(isModalOpen);
      setVisibleCancel(false);
    },
    []
  );

  const renderConfirmation = useMemo(() => {
    return (
      <BuysellConfirmation
        title={`${
          !isOrderModifying
            ? `Do you confirm to proceed with this ${capitalizeFirstLetter(
              type
            )} order?`
            : "Modify"
        }`}
        formData={formData}
        visible={visibleCancel}
        description={type === "BUY" ? BUYCONFIRMATION : SELLCONFIRMATION}
        handleModal={handleConfirmBuySell}
        handleClose={handleClose}
        isOrderModifying={isOrderModifying}
        label={type === "BUY" ? "BUY" : "SELL"}
        type={type === "BUY" ? "buy" : "sell"}
        cancelLoading={
          isbiometricLoading || modifyLoading || loading || issuanceLoading
        }
        keyPreference={inputChangeCheck.current}
      />
    );
  }, [
    isOrderModifying,
    capitalizeFirstLetter,
    type,
    visibleCancel,
    handleConfirmBuySell,
    handleClose,
    isbiometricLoading,
    modifyLoading,
    loading,
  ]);

  const handleWheel: React.WheelEventHandler<HTMLElement> = (e: any) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const renderLockInTime = useCallback(
    ({ days, hours, minutes, seconds, completed }: Json) => {
      if (completed && !isTimerCompleted.current) {
        // call the portfolio api
        isTimerCompleted.current = true;
        getUsersAssetPortfolio(activeCurrency?.id);
      }
      const time = `${!!days ? days + "D :" : ""} ${
        (hours < 10 ? "0" : "") + hours
      }H : ${(minutes < 10 ? "0" : "") + minutes}M : ${
        (seconds < 10 ? "0" : "") + seconds
      }S`;
      return (
        <div className="asset-lock-in-count">
          <CircularProgressbarWithChildren
            value={100}
            maxValue={100}
            className="count-circle"
            strokeWidth={10}
          >
            <RadialSeparators count={40} />
          </CircularProgressbarWithChildren>
          <div>
            <span className="mt-10">Time Remaining</span>
            <br />
            <span className="time-color">{time}</span>
          </div>
        </div>
      );
    },
    [activeCurrency]
  );

  const handleInputChangeCheck = useCallback((key: string) => {
    inputChangeCheck.current = { key, delay: addMilliseconds(new Date(), 500) }
  }, [])

  const lockInTime = useMemo(() => {
    const durationObj = convertToDays(
      activeCurrency?.lockupPeriod,
      assetPortfolio?.data?.lockInTradeInfo?.tradeUnlockTime
    );
    return durationObj?.futureDate;
  }, [assetPortfolio, activeCurrency?.lockupPeriod]);

  const maxTradeBalance = useMemo(() => {
    return Number(availableBalance?.balance || 0) - (Number(availableBalance?.balance || 0) * (1 / 100))
  }, [availableBalance?.balance]);

  const cryptoBuyQtyCheck = useMemo(() => {
    if (activeCurrency?.type === assestType.crypto && inputChangeCheck.current?.key === "qty" && type === "BUY") {
      return Number(formData?.total || 0) > maxTradeBalance
    }
    return false;
  }, [formData?.total, maxTradeBalance]);

  const totalValue = Number(formData.price) * Number(formData.qty);
  const errorTotal = parseFloat((formData?.total || 0) as string) < 1;
  const disablePrice = activeCurrency?.type === assestType.crypto;
  return (
    <>
      <div
        className={cn(styles.action, {
          [styles.actionSba7]: LOAN_ASSETS[activeCurrency?.type],
        })}
      >
        <div className={styles.head}>
          <div className={styles.title}>
            {symbol === "" ? (
              <Skeleton />
            ) : musicId ? (
              symbol
            ) : (
              mergeStringWithSymbol(
                activeCurrency?.type === assestType.crypto ? "" : issuerName,
                isOrderBook ? selectedOrder?.symbol : symbol?.toUpperCase(),
                "."
              )
            )}
          </div>
        </div>
        <div
          className={cn(styles.walletSection, {
            [styles.modifyingWalletSection]: isOrderModifying,
          })}
        >
          <div
            className={cn(styles.walletBalance, {
              [styles.modifyingWalletBalance]: isOrderModifying,
            })}
          >
            <div
              className={cn(styles.walletHeading, {
                [styles.modifyingWalletHeading]: isOrderModifying,
              })}
            >
              <i
                className="ri-wallet-3-line walletSvg"
                style={{ fontSize: "16px" }}
              />{" "}
              Wallet Balance :
            </div>
            <div className={styles.balanceValue}>{content}</div>
          </div>

          {activeCurrency?.type === assestType.crypto && type === "BUY" && (
            <div
              className={cn(styles.walletBalance, {
                [styles.modifyingWalletBalance]: isOrderModifying,
              })}
            >
              <div
                className={cn(styles.walletHeading, {
                  [styles.modifyingWalletHeading]: isOrderModifying,
                })}
              >
                <i
                  className="ri-wallet-3-line walletSvg"
                  style={{ fontSize: "16px" }}
                />{" "}
                Max. Trade Balance :
              </div>
              <div className={styles.balanceValue}>
                ${formatNumberWithCommas(maxTradeBalance)}
              </div>
              <i
                className="ri-information-fill"
                data-tooltip-id="buy-max-trade-balance"
              />
              <ReactTooltip
                className="theme-tooltip custom-tooltip"
                id="buy-max-trade-balance"
                place="top-end"
                content="Maximum trade amount is 99% of the cash balance available in your wallet."
              />
            </div>
          )}

          <div
            className={cn(styles.walletBalance, {
              [styles.modifyingWalletBalance]: isOrderModifying,
            })}
          >
            <div
              className={cn(styles.walletHeading, {
                [styles.modifyingWalletHeading]: isOrderModifying,
              })}
            >
              <i
                className="ri-coins-line walletSvg"
                style={{ fontSize: "16px" }}
              />
              {type === actions.SELL.toUpperCase()
                ? "Total Quantity Owned :"
                : "Quantity Owned :"}
            </div>
            <div
              className={cn(styles.balanceValue, {
                [styles.modifyingAssetQty]: isOrderModifying,
              })}
            >
              {assetPortfolio?.loading ? (
                <Skeleton height={15} />
              ) : (
                formatNumberWithCommas(
                  assetPortfolio?.data?.quantity ?? 0,
                  ASSETS_CONFIG[activeCurrency?.type]?.qtyDecimal,
                  false
                )
              )}
            </div>
          </div>

          {type === actions.SELL.toUpperCase() &&
            (activeCurrency?.type === 'privates' || activeCurrency?.type === "real_estate")
            && (
              <>
                <div
                  className={cn(styles.walletBalance, {
                    [styles.modifyingWalletBalance]: isOrderModifying,
                  })}
                >
                  <div
                    className={cn(styles.walletHeading, {
                      [styles.modifyingWalletHeading]: isOrderModifying,
                    })}
                  >
                    <i
                      className="ri-coins-line walletSvg"
                      style={{ fontSize: "16px" }}
                    />
                    Lockup Quantity :
                  </div>
                  <div
                    className={cn(styles.balanceValue, {
                      [styles.modifyingAssetQty]: isOrderModifying,
                    })}
                  >
                    {assetPortfolio?.loading ? (
                      <Skeleton height={15} />
                    ) : (
                      formatNumberWithCommas(
                        assetPortfolio?.data?.lockInTradeInfo?.lockedQuantity,
                        2,
                        false
                      ) || formatNumberWithCommas(0)
                    )}
                  </div>
                </div>

                <div
                  className={cn(styles.walletBalance, {
                    [styles.modifyingWalletBalance]: isOrderModifying,
                  })}
                >
                  <div
                    className={cn(styles.walletHeading, {
                      [styles.modifyingWalletHeading]: isOrderModifying,
                    })}
                  >
                    <i
                      className="ri-coins-line walletSvg"
                      style={{ fontSize: "16px" }}
                    />
                    Available For Trade :
                  </div>
                  <div
                    className={cn(styles.balanceValue, {
                      [styles.modifyingAssetQty]: isOrderModifying,
                    })}
                  >
                    {assetPortfolio?.loading ? (
                      <Skeleton height={15} />
                    ) : (
                      formatNumberWithCommas(
                        assetPortfolio?.data?.lockInTradeInfo?.unlockedQuantity,
                        2,
                        false
                      ) || formatNumberWithCommas(0)
                    )}
                  </div>
                </div>
              </>
            )}
        </div>
        {type !== "BUY" &&
        (activeCurrency?.type === "privates" ||
          activeCurrency?.type === "real_estate") &&
        assetPortfolio?.data?.lockInTradeInfo?.tradeUnlockTime &&
        !assetPortfolio?.data?.lockInTradeInfo?.unlockedQuantity &&
        !isModifyOrderOpen ? (
          <div className="asset-lock-in-time-container">
            <p className="asset-lock-in-des">
              This asset is currently under a lockup period and cannot be sold
              at this time.
            </p>
            <CountdownTimer dateTime={lockInTime} renderer={renderLockInTime} />
          </div>
        ) : (
          <>
            <>
              <label className={styles.field}>
                <div className={styles.label}>Price</div>
                {!isOpenAssetLoading ? (
                  <CommaSeparatedInput
                    decimalPlaces={ASSETS_CONFIG[activeCurrency?.type]?.decimal}
                    type="text"
                    name="price"
                    value={formData.price}
                    maxLength={18}
                    disabled={activeTabIndex === 1 || disablePrice}
                    className={styles.input}
                    style={{
                      color: `${activeTabIndex === 1 ? "#777E90" : ""}`,
                    }}
                    min={1}
                    onChange={(e, rawValue) => handleChange(rawValue, "price")}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                      handleKeyPress(e, "price")
                    }
                    autoComplete="off"
                  />
                ) : (
                  <Skeleton />
                )}

                <div
                  className={cn(styles.currency, {
                    [styles.bondPoint]: LOAN_ASSETS[activeCurrency?.type],
                  })}
                >
                  {LOAN_ASSETS[activeCurrency?.type]
                    ? bondPoint
                    : currencyDetails.code}
                </div>
              </label>
            </>
            {LOAN_ASSETS[activeCurrency?.type] && (
              <div
                className={cn(styles.walletBalance, {
                  [styles.modifyingWalletBalance]: isOrderModifying,
                })}
              >
                <div
                  className={cn(styles.walletHeading, {
                    [styles.modifyingWalletHeading]: isOrderModifying,
                  })}
                >
                  {/* <i
            className="ri-wallet-3-line walletSvg"
            style={{ fontSize: "16px" }}
          />{" "} */}
                  Equivalent Value :
                </div>
                {/* const amount = bondPointToAmount(auctionDetails?.currentLoanBalance,
          maxBidPrice) || 0; const avgYieldPer =
          calculateWeightedYieldPer(auctionDetails, amount, 6); return (
          <>
            Equivalent value : {formatCurrencyWithBillion(amount, 2, true)}
            <br />
            totalWeightedLoanBalance
            Equivalent weighted avg. yield : {avgYieldPer}%
          </> */}
                <div className={styles.balanceValue}>
                  {formatCurrencyWithBillion(
                    bondPointToAmount(
                      isOrderBook
                        ? isGovGuarunteed
                          ? selectedOrder?.government_guaranteed_loan_balance
                          : selectedOrder?.current_loan_balance
                        : currentLoanBalance,
                      formData?.price
                    ) || 0,
                    2,
                    true,
                    true
                  )}
                </div>
              </div>
            )}

            {StopLimit && (
              <label className={styles.field}>
                <div className={styles.label}>Limit</div>
                <input
                  type="text"
                  name="limit"
                  value={formData.limit}
                  className={styles.input}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, "limit")
                  }
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                    handleKeyPress(e, "limit")
                  }
                />
                <div className={styles.currency}>
                  {" "}
                  {LOAN_ASSETS[activeCurrency?.type]
                    ? "bond points"
                    : currencyDetails.code}
                </div>
              </label>
            )}
            <label className={styles.field}>
              <div className={styles.label}>Qty</div>
              <CommaSeparatedInput
                decimalPlaces={
                  activeCurrency?.type === assestType.crypto ? 8 : 0
                }
                type="text"
                onWheel={handleWheel}
                maxDigits={
                  activeCurrency?.type === assestType.crypto ? 15 : undefined
                }
                name="qty"
                autoComplete="off"
                value={formData.qty}
                className={styles.input}
                onChange={(e, rawValue) => handleChange(rawValue, "qty")}
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                  handleKeyPress(e, "qty")
                }
                onFocus={() => handleInputChangeCheck("qty")}
                disabled={LOAN_ASSETS[activeCurrency?.type]}
              />
            </label>
            {activeCurrency?.type === assestType.crypto ? (
              <div>
                <label
                  className={`${styles.field} ${
                    errorTotal ? styles.field_error : ""
                  }`}
                >
                  <div className={styles.label}>Total</div>
                  {!isOpenAssetLoading ? (
                    <CommaSeparatedInput
                      decimalPlaces={8}
                      type="text"
                      name="total"
                      value={formData?.total || totalValue || 0}
                      maxDigits={15}
                      disabled={activeTabIndex === 1 || type !== "BUY"}
                      className={styles.input}
                      style={{
                        color: `${activeTabIndex === 1 ? "#777E90" : ""}`,
                      }}
                      min={1}
                      onChange={(e, rawValue) =>
                        handleChange(rawValue, "total")
                      }
                      onKeyPress={(e: KeyboardEvent<HTMLInputElement>) =>
                        handleKeyPress(e, "total")
                      }
                      onFocus={() => handleInputChangeCheck("total")}
                      autoComplete="off"
                    />
                  ) : (
                    <Skeleton />
                  )}

                  <div
                    className={cn(styles.currency, {
                      [styles.bondPoint]: LOAN_ASSETS[activeCurrency?.type],
                    })}
                  >
                    {LOAN_ASSETS[activeCurrency?.type]
                      ? bondPoint
                      : currencyDetails.code}
                  </div>
                </label>
                {errorTotal && activeCurrency?.type === assestType.crypto && (
                  <p className={styles.field_error_msg}>
                    Minimum trade amount must be $1.00 or more
                  </p>
                )}
              </div>
            ) : (
              <>
                <label
                  className={`${styles.field} ${
                    errorTotal && activeCurrency?.category === assestType.crypto
                      ? styles.field_error
                      : ""
                  }`}
                >
                  <div className={styles.label}>Total</div>

                  <div className={styles.total}>
                    {formatNumberWithCommas(
                      totalValue,
                      ASSETS_CONFIG[activeCurrency?.type]?.decimal
                    )}
                  </div>
                  <div className={styles.currency}>
                    {LOAN_ASSETS[activeCurrency?.type]
                      ? bondPoint
                      : currencyDetails.code}
                  </div>
                </label>
                {errorTotal &&
                  activeCurrency?.category === assestType.crypto && (
                    <p className={styles.field_error_msg}>
                      Minimum trade amount must be $1.00 or more
                    </p>
                  )}
              </>
            )}

            {LOAN_ASSETS[activeCurrency?.type] && (
              <div
                className={cn(styles.walletBalance, {
                  [styles.modifyingWalletBalance]: isOrderModifying,
                })}
              >
                <div
                  className={cn(styles.walletHeading, {
                    [styles.modifyingWalletHeading]: isOrderModifying,
                  })}
                >
                  {/* <i
            className="ri-wallet-3-line walletSvg"
            style={{ fontSize: "16px" }}
          />{" "} */}
                  Equivalent yield :
                </div>
                <div className={styles.balanceValue}>
                  {/* 10.260000% const */}
                  {formatNumberWithCommas(
                    calculateWeightedYieldPer(
                      {
                        metadata: {
                          totalWeightedLoanBalance:
                            activeCurrency?.totalWeightedLoanBalance,
                        },
                      },
                      bondPointToAmount(currentLoanBalance, formData?.price) ||
                        0,
                      6
                    )
                  )}
                  {""}%
                </div>
              </div>
            )}
            {fee[type.toLowerCase()] === 0 ? (
              ""
            ) : (
              <p className={styles.fees}>
                {" "}
                {fee?.[type.toLowerCase()]}% fees is included in total
              </p>
            )}
            {!isOrderModifying && (
              <div className={styles.goodTillCancel}>
                <div>
                  <p className={styles.goodTillCancelTitle}>
                    Good till canceled <i className="ri-information-fill"></i>
                  </p>
                  {goodTillCancelled ? (
                    <p className={styles.goodTillCancelSubTitle}></p>
                  ) : (
                    <p className={styles.goodTillCancelSubTitle}>
                      This order will expire at 12:00 AM EST
                    </p>
                  )}
                </div>

                <div>
                  <ReactSwitch
                    checked={goodTillCancelled}
                    handleChange={handleToggle}
                    onColor="#3772FF"
                    className={
                      goodTillCancelled
                        ? "react-switch-handle-checked"
                        : "react-switch-handle-unchecked"
                    }
                  />
                </div>
              </div>
            )}
          </>
        )}

        {/* {(type === "BUY" ||
          !(!assetPortfolio?.data?.lockInTradeInfo?.tradeUnlockTime &&
            !assetPortfolio?.data?.lockInTradeInfo?.unlockedQuantity)) && (

        )} */}

        <button
          disabled={
            activeCurrency?.symbol === MUTUAL_FUND.Asset ||
            (type === "SELL" && (activeCurrency?.type === 'privates' || activeCurrency?.type === "real_estate") && !isOrderModifying &&
              !assetPortfolio?.data?.lockInTradeInfo?.unlockedQuantity) ||
            isBtnDisable ||
            !(
              activeCurrency.type === assestType.privates ||
              activeCurrency.type === assestType.realEstate ||
              activeCurrency.type === assestType.music ||
              LOAN_ASSETS[activeCurrency?.type]
            ) ||
            modifyLoading ||
            loading ||
            Number(formData.qty) === 0 ||
            UserLoading ||
            cryptoBuyQtyCheck ||
            initiateBiometric
          }
          onClick={handleButtonClick}
          className={cn(classButton, styles.button)}
        >
          {!(loading || initiateBiometric) && !modifyLoading ? (
            buttonText
          ) : (
            <Loader className="loader-white" dimension={30} />
          )}
        </button>
      </div>
      <ReactModal
        visible={visibleCancel}
        onClose={() => setVisibleCancel(false)}
        maskClosable={false}
        outerClassName={styles.confirmationModalOuter}
      >
        {renderConfirmation}
      </ReactModal>
      {initiateBiometric && (
        <DeviceBiometrics
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          render={(isWebAuthLoading: boolean, isModalOpen: boolean) =>
            setModalVisible(isWebAuthLoading, isModalOpen)
          }
        />
      )}
      {primaryIssuance?.signDocModal && (
        <IssuanceSignDoc onSuccess={handelOrderPlace} />
      )}
    </>
  );
};
